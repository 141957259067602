import { render, staticRenderFns } from "./mallSettingSH.vue?vue&type=template&id=530a1ee0&scoped=true&"
import script from "./mallSettingSH.vue?vue&type=script&lang=js&"
export * from "./mallSettingSH.vue?vue&type=script&lang=js&"
import style0 from "./mallSettingSH.vue?vue&type=style&index=0&id=530a1ee0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530a1ee0",
  null
  
)

export default component.exports